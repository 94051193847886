import Storage from "@/util/Storage";
import { GetShopTypeList,GetShopComment } from "@/Api/Shop";

export function getCommodity(storeId = "") {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return (
        new Promise((resolve, reject) => {
            GetShopTypeList({ userId,token },{ storeId }).then(res=>{
                try{
                    resolve(res.data.catalogList || [])
                }catch (e) {
                    reject(res.message.msg)
                }
            })
        }).then((res:any[])=>{
            return Promise.all(res.map(item=>GetShopComment({ userId,token },{ catalogId:item.id || '' }))).then((catalogList:any[])=>{
                return Promise.resolve(catalogList.map((item,index)=>{
                    return {
                        ...res[index],
                        text:res[index]["name"],
                        list:(item.data.productList || [])
                    }
                }));
            })
        })
    )
}
