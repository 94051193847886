import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store"
import SelectCommodityStoreType from "@/views/ShopAdmin/SelectCommodity/Store/indexType";

@Module({
    name:"SelectCommodity",
    store,
    dynamic: true,
})
export default class SelectCommodity extends VuexModule implements SelectCommodityStoreType{
    SelectCommoditySelData: any = [];
    get getSelectCommoditySelData(){ return this.SelectCommoditySelData }
    @Mutation
    SetSelectCommoditySelData(data: any): void {
        this.SelectCommoditySelData = data
    }
}

export const SelectCommodityStore = getModule( SelectCommodity )
